export const Heading = ({ children, className }) => {
    return (
        <h2
            className={
                "text-2xl sm:text-3xl text-primary-500 font-headline font-bold " + className
            }
        >
            {children}
        </h2>
    );
};

export const SubHeading = ({ children, className }) => {
    return <p className={"text-lg text-gray-900 " + className}>{children}</p>;
};
